<template lang="html">
  <svg viewBox="0 0 36 36" class="circular-chart">
    <path class="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
    <path class="circle" :stroke-dasharray="score * 10" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
    <defs>
      <linearGradient id="gradient" x1="12.232" y1="3.0666e-07" x2="40" y2="40" gradientUnits="userSpaceOnUse">
        <stop stop-color="#522CF7"/>
        <stop offset="0.74175" stop-color="#8623CF"/>
        <stop offset="1" stop-color="#9322C6"/>
      </linearGradient>
    </defs>
    <text x="18" y="21" class="percentage">{{ popularityScore }}%</text>
  </svg>
</template>

<script>
  export default {
    props: {
      score: {
        type: Number,
        required: true
      }
    },
    computed: {
      popularityScore() {
        return Math.round(this.score * 10)
      }
    }
  }
</script>
